import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConstantsService {
  docServiceUrl = environment["docServiceUrl"]
  bdDashboardRoute = this.docServiceUrl + "/bd-dashboard/"
  cxDashDocRoute = this.docServiceUrl + '/cx-dashboard/'
  srPortalRoute = this.docServiceUrl + "/sr-portal/"
  getDiseaseBasedOnCategory = this.bdDashboardRoute + "getDiseaseBasedOnCategory"
  getLatestUserData = this.docServiceUrl + "/getUser";
  forwardingApi = this.bdDashboardRoute + "forwarding"
  signedUrl = this.docServiceUrl + "/EMR/getSignedUrl"
  signedUrlJava = environment["javaUrl"] + "api/getSignedUrl"
  bdCategoryWiseList = this.bdDashboardRoute + "bdCategoryWiseList";

  //auth
  authAPI = this.docServiceUrl + "/auth/"
  requestOtp = this.authAPI + "request-otp"
  submitOtp = this.authAPI + "submit-otp"
  refreshToken = this.authAPI + "refresh-token"
  logout = this.authAPI + "logout"

  //panel-api
  getCommonDataForProduct = this.srPortalRoute + "getCommonDataForProduct"
  getCommonDataForMarketing = this.srPortalRoute + "getCommonDataForMarketing"
  getBDListBasedOnCateTeam = this.srPortalRoute + "getBDListBasedOnCateTeam"
  addSRNumberInSystem = this.srPortalRoute + "addSRNumberInSystem"
  updateSRNUmberLeadAssignment = this.srPortalRoute + "updateSRNUmberLeadAssignment"
  filterapi = this.srPortalRoute + "filterapi"

  updateCXDashDataQuery = this.cxDashDocRoute + 'updateCXDashDataQuery';
  updateSRNUmberStatus = this.srPortalRoute + 'updateSRNumberStatus';
  getTeam = this.srPortalRoute + 'getTeam';
  srNumberAlert = this.srPortalRoute + 'srNumberAlert';
  SRNumberhistory = this.srPortalRoute + 'srNumberHistory';

  //leadAlternate
  uploadCityCSVFile = environment["leadsAlternative"]+"/uploadCityCSVFile";
  uploadCategoryCSVFile = environment["leadsAlternative"]+"/uploadCategoryCSVFile";
  downloadCityCSVFile = environment["leadsAlternative"]+"/downloadCityCSVFile";
  downloadCategoryTeamCSVFile = environment["leadsAlternative"]+"/downloadCategoryTeamCSVFile";
  getDoctorsFilter=this.docServiceUrl+"/lead-assignment-rules/get-doctors-filter"
  downloadReport=this.docServiceUrl+"/sr-portal/SRNumberLeadDownload"

  constructor() {}

}