export const environment = {
  production: true,
  version: "0.0.0",
  // prod
   docServiceUrl :"https://pristyntech.com/doc",
  // staging
  // docServiceUrl :"http://13.126.149.224/doc_v2",
  //local
  // docServiceUrl :"http://localhost:8080"
   leadsAlternative : "https://pristyntech.com/leadsAlternative/api",
   javaUrl : "https://pristyntech.com/java/"
};
